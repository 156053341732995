import Axios from '../config/Axios';
import loadBreadcrumb from './ActionBreadcrumb';

export function confirmReading(nVersionId) {
  return Axios.post('/version/read', { ver_nid: nVersionId });
}

export function fileDocument(nDocumentId) {
  return Axios.put(`/file/${nDocumentId}`);
}

export function getDocumentDetails(nVersionId) {
  return Axios.get(`document/detail/${nVersionId}`).then(
    oResponse =>
      new Promise((resolve, reject) => {
        loadBreadcrumb(oResponse.data.oDocument.fldr_nid)
          .then(aBreadcrumbFolders => {
            resolve({
              ...oResponse.data,
              oDocument: oResponse.data.oDocument,
              oVersion: oResponse.data.oVersion,
              bCanEditInFolder: oResponse.data.bCanEditInFolder,
              bDocActive: oResponse.data.bDocActive,
              aBreadcrumbFolders
            });
          })
          .catch(oError => reject(oError));
      })
  );
}

export async function fetchAttachment(nVersionId) {
  return Axios.get(`document/detail/${nVersionId}/attachments`).then(
    oResponse =>
      new Promise((resolve) => {
        resolve({
          aAttachments: oResponse.data.aAttachments
        });
      })
  );
}

export async function fetchRequisites(nVersionId) {
  return Axios.get(`document/detail/${nVersionId}/requisites`).then(
    oResponse =>
      new Promise((resolve) => {
        resolve({
          aRequisites: oResponse.data.aRequisites
        });
      })
  );
}

export async function fetchVersion(nVersionId) {
  return Axios.get(`document/detail/${nVersionId}`).then(
    oResponse =>
      new Promise((resolve) => {
        resolve({
          ...oResponse.data,
          oDocument: oResponse.data.oDocument,
          oVersion: oResponse.data.oVersion,
          bCanEditInFolder: oResponse.data.bCanEditInFolder,
          bDocActive: oResponse.data.bDocActive
        });
      })
  );
}


function fnDownloadFileByUrl(cAttachmentUrl, cFileName) {
  const xhr = new XMLHttpRequest();
  xhr.responseType = "blob";
  xhr.onload = () => {
    const a = document.createElement("a");
    a.href = window.URL.createObjectURL(xhr.response);
    a.download = cFileName;
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
  };

  xhr.open("GET", cAttachmentUrl);
  xhr.send();
}

export function downloadPDF(nVersionId, cFileName, cFormat, cOrientation, cPreview, cServerPrint = 'L') {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("cStorageToken");

    let apiURL;
    let headers = {};

    if (cServerPrint === 'P') {
      apiURL = `${process.env.REACT_APP_CONVERT_URL}/printDocument/${nVersionId}/${cFormat}/${cOrientation}/${cPreview}`; // Servidor Python
    } else if (cServerPrint === 'N') {
      apiURL = `${process.env.REACT_APP_CONVERT_URL}/api/printDocument/${nVersionId}/${cFormat}/${cOrientation}/${cPreview}`; // Servidor Node.js
    } else {
      apiURL = `version/pdf/download/${nVersionId}/${cFormat}/${cOrientation}/${cPreview}`; // Servidor padrão
    }

    if (cServerPrint === 'P' || cServerPrint === 'N') {
      headers = {
        "Authorization": `Bearer ${token}`,
        "Accept": "application/pdf"
      };
    }

    Axios.get(apiURL, {
      responseType: 'blob',
      headers
    })
      .then((oResponse) => {
        const fFile = new Blob([oResponse.data], { type: oResponse.data.type });
        const cFileURL = URL.createObjectURL(fFile);
        const elLink = document.createElement('a');
        elLink.href = cFileURL;
        elLink.setAttribute('download', `${cFileName}.pdf`);
        document.body.appendChild(elLink);
        elLink.click();
        resolve();
      })
      .catch(oError => new Response(oError.response.data).text().then(cText => reject(cText)));
  });
}


export function fnGetLocalImageAttachment(nAttachmentId, cFileName) {
  return new Promise((resolve, reject) =>
    Axios.get(`attachment/download/${nAttachmentId}`, { responseType: "blob" })
      .then(oResponse => {
        resolve({
          cContent: URL.createObjectURL(oResponse.data),
          cType: "blob"
        });
      })
      .catch(oError => new Response(oError.response.data).text().then(cText => reject(cText)))
  );
}

export function fnGetImageAttachment(nAttachmentId, cFileName, bIsLocal) {
  if (bIsLocal) {
    return fnGetLocalImageAttachment(nAttachmentId, cFileName);
  }

  return Axios.get(`attachment/download/${nAttachmentId}`, { responseType: 'blob' }) // Define 'blob' para lidar com arquivos binários
    .then(async (oResponse) => {
        const contentType = oResponse.headers['content-type']; // Obtém o tipo de conteúdo do cabeçalho da resposta

        // Se for um JSON (ou seja, o backend retornou uma URL)
        if (contentType.includes('application/json')) {
            const reader = new FileReader();
            reader.readAsText(oResponse.data);
            
            return new Promise((resolve, reject) => {
                reader.onload = () => {
                    try {
                        const jsonResponse = JSON.parse(reader.result);
                        const cAttachmentUrl = jsonResponse.data?.cUrl;

                        if (!cAttachmentUrl) {
                            return reject("Erro: URL do anexo não encontrada.");
                        }

                        const cCleanUrl = cAttachmentUrl.split('?')[0].trim();
                        
                        // Se for um documento que precisa de conversão
                        if (/\.(docx?|xlsx?|html|txt|ods)$/i.test(cCleanUrl)) {
                            const formData = new FormData();
                            formData.append('file', cAttachmentUrl); // Substituir por upload real, se necessário
                            const cUrlConvert = `${process.env.REACT_APP_CONVERT_URL}/convert`;

                            Axios.post(cUrlConvert, formData)
                                .then((oConversionResponse) => {
                                    const cConvertedPdfBase64 = oConversionResponse.data.pdf_content;
                                    resolve({ cContent: cConvertedPdfBase64, cType: 'pdf' });
                                })
                                .catch(() => reject("Erro na conversão para PDF"));
                        } else {
                            resolve({ cContent: cAttachmentUrl, cType: 'url' });
                        }
                    } catch (error) {
                        reject("Erro ao processar resposta JSON");
                    }
                };
            });
        } 
        
        // Se for um arquivo binário (PDF, imagem, etc.)
        else {
            const blobUrl = window.URL.createObjectURL(oResponse.data);
            return { cContent: blobUrl, cType: contentType };
        }
    })
    .catch(() => Promise.reject("Erro na obtenção do anexo"));


}



export function fnDownloadLocalAttachment(nAttachmentId, cFileName) {
  return new Promise((resolve, reject) =>
    Axios.get(`attachment/download/${nAttachmentId}`, {
      responseType: "blob"
    })
      .then(oResponse => {
        const fFile = new Blob([oResponse.data], { type: oResponse.data.type });
        const cFileURL = URL.createObjectURL(fFile);
        const elLink = document.createElement('a');
        elLink.href = cFileURL;
        elLink.setAttribute('download', `${cFileName}`);
        document.body.appendChild(elLink);
        elLink.click();
        resolve();
      })
      .catch(oError => new Response(oError.response.data).text().then(cText => reject(cText)))
  );
}

export function downloadAttachment(nAttachmentId, cFileName, cFileUrl = '') {
  return new Promise((resolve, reject) => {
    Axios.get(`attachment/download/${nAttachmentId}`)
      .then(oResponse => {
        const cAttachmentUrl = oResponse.data?.data?.cUrl;

        if (cFileUrl !== '') {
          // Se 'URL' for passado, retorna apenas a URL do anexo
          resolve({ cUrl: cAttachmentUrl });
        } else {
          // Caso contrário, baixa o arquivo
          fnDownloadFileByUrl(cAttachmentUrl, cFileName);
          resolve();
        }
      })
      .catch(oError => {
        new Response(oError.response.data).text().then(cText => reject(cText));
      });
  });
}



export function downloadZip(nVersionId, cFileName) {
  return new Promise((resolve, reject) =>
    Axios.get(`/version/zip/download/${nVersionId}`, { responseType: 'blob' })
      .then(oResponse => {
        const fFile = new Blob([oResponse.data], { type: oResponse.data.type });
        const cFileURL = URL.createObjectURL(fFile);
        const elLink = document.createElement('a');
        elLink.href = cFileURL;
        elLink.setAttribute('download', cFileName);
        document.body.appendChild(elLink);
        elLink.click();
        resolve();
      })
      .catch(oError => new Response(oError.response.data).text().then(cText => reject(cText)))
  );
}
