// atenção: na próxima vez que precisarmos usar HTML perigoso, lembremos de sanitizá-lo para aplicar a sanitização nesses dangerouslySetInnerHTML também
import _ from 'lodash';
import React, { Component } from 'react';
import { Button } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert/lib/dist/SweetAlert';
import intl from 'react-intl-universal';
import { NavLink } from 'react-router-dom';
import { Watermark } from '@hirohe/react-watermark';
import {
  fileDocument,
  confirmReading,
  downloadAttachment,
  downloadZip,
  getDocumentDetails,
  fetchAttachment,
  fetchVersion,
  fetchRequisites,
  fnDownloadLocalAttachment,
  fnGetImageAttachment
} from '../../actions/ActionDocumentDetails';
import AltCheckbox from '../../components/AltCheckbox';
import { unfile } from '../../actions/ActionVersionHistory';
import imgfNoPic from '../../assets/img/nopic.svg';
import Breadcrumb from '../../components/Breadcrumbs';
import url from '../../config/Url';
import Page from '../default-v2/Page';

import RequestModal from '../request/RequestModal';
import DocumentEdit from './DocumentEdit';
import DocumentMove from './DocumentMove';
import NewVersion from './NewVersion';
import RequisiteShow from '../norm/RequisiteShow';
import Axios from '../../config/Axios';
import ScrollBar from '../../components/ScrollBar';
import CardHeader from '../default-v2/CardHeader';
import './styles/documentdetails.scss';
import './styles/documentshared.scss';
import OutsideHandlerClick from '../../components/OutsideHandlerClick';
import Attachment from './Attachments';
import { can, checkModule, hasModule, canAtLeast } from '../../config/Permissions';
import 'froala-editor/css/froala_style.min.css';
import '../../assets/scss/froala-custom.scss';
import cLoadingImage from '../../assets/img/preloader-black.gif';
import PrintConfig from './PrintConfig';
import documentImg from '../../assets/img/documents.svg';
import cImageDownload from './img/icon-download.svg';

import { oLocalStorage } from '../../config/EncodedLocalStorage';
import TreeViewLayer from '../../components/TreeViewLayer';
import authorizedFolders from '../../actions/ActionAuthorizedFolders';
import { formatDateToUserTZ } from '../../utils/time';
import PdfFireViewer from '../../components/PdfFireViewer';
//import { TailSpin } from 'react-loader-spinner';

class DocumentDetails extends Component {
  constructor(oProps) {
    super(oProps);

    this.state = {
      bLoading: true,
      nVersionId: oProps.match.params.nVersionId,
      aImgFiles: ['png', 'jpg', 'jpeg', 'bmp', 'ai', 'eps', 'psd'],
      aAttachments: [],
      oDocument: [],
      oDocumentWriter: [],
      oDocumentResponsible: [],
      oDocumentReviewer: [],
      oVersion: {
        aVersionReadings: [],
        aEvidences: []
      },
      cPreProcessedText: '',
      bOpenState: false,
      bGenZip: false,
      bConfirmedRead: false,
      bPendingRead: false,
      bCanEditInFolder: false,
      bDocActive: true,
      oTreeViewFolder: [],
      nRestoringFolderId: 1,
      bShowSpinner: false,
      shareIsPublic: false,
      shareData: null,
      rcmpAlert: null,
      shareError: '',
      isActive: null,
      nDocId: null,
      bLoadingShare: false,
      isSubmitting: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { shareData, shareIsPublic, bLoadingShare } = this.state;
    if (prevState.shareData !== shareData || prevState.shareIsPublic !== shareIsPublic) {
      //console.log('ShareData atualizado:', shareData);
      this.setState({ bLoadingShare: false, rcmpAlert: this.renderAlert() });
    }
  }


  generateShareLink = () => {
    if (this.state.isSubmitting) return;
    this.setState({ isSubmitting: true }, () => {
      const bLoadingShareValue = this.state.isActive === 'D' ? true : false;
      const bLoadingSharePublicValue = this.state.isActive === 'A' ? true : false;
      this.setState({ bLoadingShare: bLoadingShareValue, bLoadingSharePublic: bLoadingSharePublicValue }, () => {
        const { nDocId, shareIsPublic, bLoadingShare, bLoadingSharePublic, isActive } = this.state;
        if (bLoadingShare || bLoadingSharePublic) {
          this.setState({ rcmpAlert: this.renderAlert() });
        }
        Axios.post(`/document/share/${nDocId}`, { share_ispublic: shareIsPublic ? 1 : 0, isactive: isActive })
          .then(response => {
            this.setState({
              shareData: response.data,
              bLoadingShare: false,
              bLoadingSharePublic: false,
              rcmpAlert: this.renderAlert(),
              isSubmitting: false
            });
          })
          .catch(error => {
            this.setState({
              bLoadingShare: false,
              bLoadingSharePublic: false,
              shareError: error.message,
              isSubmitting: false
            });
          });
      });
    });
  };



  renderAlert = () => {
    const { shareData, bLoadingShare, bLoadingSharePublic } = this.state;

    if (bLoadingShare) {
      return (
        <div className="loading-overlay">
          <div>
            <img src={cLoadingImage} alt={intl.get('loading')} className="loading-image" />
          </div>
        </div>
      );
    }

    if (bLoadingSharePublic) {
      return (
        <SweetAlert
          customClass="modal-edit modalMedium scrollBar share-modal"
          title=""
          onConfirm={this.hideAlert}
          showConfirm={false}
        >
          <div className="head-modal">
            <div className="share-title">{intl.get('DocumentDetails.compartilhar_documento')}</div>
            <span
              role="button"
              tabIndex="0"
              aria-labelledby="head-modal"
              onKeyPress={this.hideAlert}
              onClick={this.hideAlert}
              className="close"
              href=""
            />
          </div>
          <div className="share-modal-content">
            <div className="share-flex">
              <div className="share-checkbox">
                <AltCheckbox
                  title={intl.get('DocumentDetails.tornar_documento_publico')}
                  label={intl.get('DocumentDetails.tornar_documento_publico')}
                  checked={shareData?.share_ispublic}
                  onChange={this.handleTogglePublic}
                  className="split1"
                />
              </div>
              <div className="share-warning">
                <span dangerouslySetInnerHTML={{ __html: intl.get('DocumentDetails.aviso_publico') }}></span>
              </div>
            </div>
            <div>
              <img src={cLoadingImage} alt={intl.get('loading')} className="loading-image" />
            </div>
          </div>
        </SweetAlert>
      );
    }

    return (
      shareData && (
        <SweetAlert
          customClass="modal-edit modalMedium scrollBar share-modal"
          title=""
          onConfirm={this.hideAlert}
          showConfirm={false}
        >
          <div className="head-modal">
            <div className="share-title">{intl.get('DocumentDetails.compartilhar_documento')}</div>
            <span
              role="button"
              tabIndex="0"
              aria-labelledby="head-modal"
              onKeyPress={this.hideAlert}
              onClick={this.hideAlert}
              className="close"
              href=""
            />
          </div>
          <div className="share-modal-content">
            <div className="share-flex">
              <div className="share-checkbox">
                <AltCheckbox
                  title={intl.get('DocumentDetails.tornar_documento_publico')}
                  label={intl.get('DocumentDetails.tornar_documento_publico')}
                  checked={shareData?.share_ispublic}
                  onChange={this.handleTogglePublic}
                  className="split1"
                />
              </div>
              <div className="share-warning">
                <span dangerouslySetInnerHTML={{ __html: intl.get('DocumentDetails.aviso_publico') }}></span>
              </div>
            </div>
            <div className="share-box">
              <div className="share-row">
                <div className="share-col text-center">
                  <div className="icon-container icon-container-left">
                    <i className="icon fa fa-link" aria-hidden="true"></i>
                  </div>
                  <input type="hidden" readOnly value={shareData?.share_link || ''} />
                  <button className="share-btn-secondary" onClick={() => this.copyToClipboard(shareData?.share_link || '')}>
                    {intl.get('DocumentDetails.copiar_link')}
                  </button>
                </div>
                <div className="share-col text-center">
                  <div className="icon-container icon-container-center">
                    <i className="icon fa fa-qrcode" aria-hidden="true"></i>
                  </div>
                  <button className="share-btn-secondary" onClick={() => this.downloadQRCode(shareData.share_qrcode)}>
                    {intl.get('DocumentDetails.baixar_qrcode')}
                  </button>
                </div>
                <div className="share-col text-center">
                  <div className="icon-container icon-container-right">
                    <i className="icon fa fa-code" aria-hidden="true"></i>
                  </div>
                  <textarea className="share-hidden-textarea" readOnly value={shareData?.share_embed || ''}></textarea>
                  <button className="share-btn-secondary" onClick={() => this.copyToClipboard(shareData?.share_embed || '')}>
                    {intl.get('DocumentDetails.copiar_embed')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </SweetAlert>
      )
    );
  };


  handleShareDocument = (evt, nDocId) => {
    evt.preventDefault();
    this.setState({ isActive: 'D' });
    this.generateShareLink(nDocId);
    this.setState({ shareData: null, nDocId }, () => {
      this.setState({ shareIsPublic: null, rcmpAlert: this.renderAlert() });
    });
  };


  downloadQRCode = (qrcodeUrl) => {
    const link = document.createElement('a');
    link.href = qrcodeUrl;
    link.download = 'qrcode.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  handleTogglePublic = (event) => {
    this.setState({ shareIsPublic: event.target.checked, isActive: 'A' });
    this.generateShareLink();
  };


  copyToClipboard = (text) => {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    textarea.style.position = 'absolute';
    textarea.style.left = '-9999px';
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  };

  componentDidMount = () => {
    const { nVersionId } = this.state;
    const { history } = this.props;
    checkModule(history, 'documents');

    fetchAttachment(nVersionId)
      .then(oData => {
        this.setState({
          aAttachments: oData.aAttachments
        });
      })
      .catch(oError => {
        history.push({
          pathname: '/',
          state: { aAlertMessages: { cType: 'error', cMsg: oError.aMsgErrors } }
        });
      });

      fetchRequisites(nVersionId)
      .then(oData => {
        this.setState({
          aRequisites: oData.aRequisites
        });
      })
      .catch(oError => {
        history.push({
          pathname: '/',
          state: { aAlertMessages: { cType: 'error', cMsg: oError.aMsgErrors } }
        });
      });

    fetchVersion(nVersionId)
      .then(oData => {
        this.setState({
          ...oData,
          oDocument: oData.oDocument,
          oVersion: oData.oVersion,
          bCanEditInFolder: oData.bCanEditInFolder,
          bDocActive: oData.bDocActive,
          bPendingRead: oData.bHasPendingRead,
          bConfirmedRead: !(oData.oVersion.version_readings.length <= 0),
          bLoading: false,
        });

        if (nVersionId !== oData.oVersion?.ver_nid) {
          this.setState({ nVersionId: `${oData.oVersion.ver_nid}` });
          window.history.replaceState(null, null, `/document/detail/${oData.oVersion.ver_nid}`);
        }
      })
      .catch(oError => {
        history.push({
          pathname: '/',
          state: { aAlertMessages: { cType: 'error', cMsg: oError.aMsgErrors } }
        });
      });

    authorizedFolders().then(aFolders => this.setState({ oTreeViewFolder: aFolders }));
  };

  handleClickOutside = evt => {
    this.setState({
      bOpenState: false
    });
  };

  handleConfirmReading = evt => {
    evt.preventDefault();

    const { setAlert } = this.props;
    const { nVersionId } = this.state;

    confirmReading(nVersionId)
      .then(() => {
        this.setState({ bConfirmedRead: true });
        setAlert('success', intl.get('DocumentDetails.leitura_confirmada'));
      })
      .catch(oError => {
        setAlert('error', oError.aMsgErrors);
      });
  };

  openModalMoveDocument = (evt, oDocument) => {
    evt.preventDefault();
    const { history } = this.props;

    this.setState({
      rcmpAlert: (
        <SweetAlert
          customClass="modal-edit modalMedium scrollBar"
          title=""
          onConfirm={this.hideAlert}
          showConfirm={false}
        >
          <div className="head-modal">
            {intl.get('DocumentMove.mover_documento')}
            <span
              role="button"
              tabIndex="0"
              aria-labelledby="head-modal"
              onKeyPress={this.hideAlert}
              onClick={this.hideAlert}
              className="close"
              href=""
            />
          </div>
          <ScrollBar>
            <DocumentMove
              oDocument={oDocument}
              onSuccess={cMsg => {
                history.push({
                  state: { aAlertMessages: { cType: 'success', cMsg } }
                });
              }}
              closeModal={this.hideAlert}
            />
          </ScrollBar>
        </SweetAlert>
      )
    });
  };

  fnHandleDownloadFile(evt) {
    evt.preventDefault();

    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = () => {
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(xhr.response);
      a.download = this.state.oDocument.doc_ctitle;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
    };

    xhr.open('GET', this.state.oVersion.ver_cfile_path);
    xhr.send();
  }

  openModalRequest = (evt, nVersionId) => {
    evt.preventDefault();
    const { setAlert } = this.props;

    this.setState({
      rcmpAlert: (
        <RequestModal
          cTitle={intl.get('DocumentDetails.suggest_changes')}
          cType="update"
          nVersionId={nVersionId}
          onSuccess={this.successMessage}
          hideAlert={this.hideAlert}
          setAlert={setAlert}
          closeModal={this.hideAlert}
          nQtyCharacters={5000}
        />
      )
    });
  };

  checkIfAttachmentIsImage = (cFilename) => {
    const { aImgFiles } = this.state;
    for (let i = 0; i < aImgFiles.length; i++) {
      if (cFilename.endsWith(aImgFiles[i])) {
        return true;
      }
    }
    return false;
  }

  openAttachmentImageModal = (oAttachment, fileType) => {
    const bIsLocal = oAttachment.atch_clocal.startsWith("customer\\");
    fnGetImageAttachment(oAttachment.atch_nid, oAttachment.atch_cname, bIsLocal).then(oData => {
      let cImageSource = oData.cContent;

      if (oData.cType == "base64") {
        cImageSource = "data:image/png;base64, " + oData.cContent;
      }

      if (oData.cType === 'application/pdf') {
        const base64Content = oData.cContent;
        cImageSource = base64Content;//`data:application/pdf;base64, ${base64Content}`;
      }

      this.setState({
        rcmpAlert: (
          <SweetAlert
            customClass="modal-edit modalMedium scrollBar"
            title=""
            onConfirm={this.hideAlert}
            showConfirm={false}
          >
            <div className="head-modal">
              {intl.get("DocumentEditor.view_attachment")}
              {(can('print') || can('admin')) && (
              <span
                role="button"
                tabIndex="0"
                aria-labelledby="head-modal"
                onClick={() => (bIsLocal) ?
                  fnDownloadLocalAttachment(oAttachment.atch_nid, oAttachment.atch_cname) : downloadAttachment(oAttachment.atch_nid, oAttachment.atch_cname)}
                className="attachment-download"
                href=""
              ><img src={cImageDownload} /></span>
              )}
              <span
                role="button"
                tabIndex="0"
                aria-labelledby="head-modal"
                onKeyDown={this.handleKeyDown}
                onClick={this.hideAlert}
                className="close"
                href=""
              />
            </div>
            {['png', 'jpg', 'jpeg', 'bmp'].includes(fileType) ? (
              <img className="attachment-image" src={cImageSource} />
            ) : (
              <>
                {fileType === 'pdf' ? (
                  <PdfFireViewer pdfBlob={cImageSource} canDownload={can('print')}/>                  
                ) : (
                  // Se não for PDF nem imagem, usa iframe (para outros formatos como .docx)
                  <PdfFireViewer pdfBlob={`data:application/pdf;base64, ${cImageSource}`} canDownload={can('print')} nameFile={oAttachment.atch_cname}/>   
                )}
              </>
            )}
          </SweetAlert>
        )
      });

      this.setState({ bShowSpinner: false });
    }).catch(oError => {
      // ...lidar com erro
      this.setState({ bShowSpinner: false });
    });
  }

  openModalFileRequest = (evt, nVersionId) => {
    const { setAlert } = this.props;
    evt.preventDefault();
    this.setState({
      rcmpAlert: (
        <RequestModal
          cTitle={intl.get('FolderIndex.label_solicitar_envio_arquivo')}
          cType="delete"
          nVersionId={nVersionId}
          onSuccess={this.successMessage}
          hideAlert={this.hideAlert}
          setAlert={setAlert}
          closeModal={this.hideAlert}
        />
      )
    });
  };

  handleDownloadAttachment = (evt, oAttachment) => {
    const { setAlert } = this.props;
    evt.preventDefault();
    const attachmentType = oAttachment.atch_cname.split('.').pop().toLowerCase();

    if (['png', 'jpg', 'jpeg', 'bmp', 'pdf', 'docx', 'doc', 'xls', 'xlsx', 'html', 'txt', 'ods'].includes(attachmentType)) {
      this.setState({ bShowSpinner: true });
      this.openAttachmentImageModal(oAttachment, attachmentType);
    } else {
      const bIsLocal = oAttachment.atch_clocal.startsWith("customer\\");
      if (bIsLocal) {
        fnDownloadLocalAttachment(oAttachment.atch_nid, oAttachment.atch_cname).catch(oError => setAlert('error', oError));
      } else {
        downloadAttachment(oAttachment.atch_nid, oAttachment.atch_cname).catch(oError => setAlert('error', oError));
      }
    }
  };

  openMenu = evt => {
    evt.preventDefault();
    this.setState(oPrevState => ({
      bOpenState: !oPrevState.bOpenState
    }));
  };

  strSlug = cStr =>
    cStr
      .toString()
      .trim()
      .toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/[^\w]+/g, '-')
      .replace(/\+/g, '-')
      .replace(/^-+/, '')
      .replace(/-+$/, '');

  handleDownloadZip = evt => {
    evt.preventDefault();

    const { oVersion } = this.state;
    const { setAlert } = this.props;

    this.setState({ bGenZip: true });

    downloadZip(oVersion.ver_nid, `${this.strSlug(oVersion.document.ver_ctitle)}${oVersion.ver_nid}.zip`)
      .then(() => this.setState({ bGenZip: false }))
      .catch(() => {
        setAlert('error', intl.get('DocumentDetails.problemas_zip'));
        this.setState({ bGenZip: false });
      });
  };

  unfileDocument = oDocument => {
    const { history } = this.props;
    const { nRestoringFolderId } = this.state;

    unfile(oDocument.doc_nid, nRestoringFolderId)
      .then(oResponse => {
        this.setState({
          rcmpAlert: (
            <SweetAlert
              success
              title={intl.get('DocumentDetails.restore_title')}
              onConfirm={() => {
                this.hideAlert();
                history.push({
                  pathname: history.pathname
                });
              }}
            >
              {intl.get('DocumentDetails.restore_success')}
            </SweetAlert>
          )
        });
      })
      .catch(oError => {
        this.setState({
          rcmpAlert: (
            <SweetAlert error title={intl.get('DocumentDetails.restore_title')} onConfirm={this.hideAlert}>
              {intl.get('DocumentDetails.restore_error')}
            </SweetAlert>
          )
        });
      });
  };

  handleChangeRestoreFolder = (evtChangeFolder) => {
    this.setState({
      nRestoringFolderId: evtChangeFolder.target.value
    });
  }

  moveFiledDocumentAlert = (oDocument) => {
    const { oTreeViewFolder } = this.state;

    return (
      <SweetAlert
        showCancel
        confirmBtnText={intl.get('confirmar')}
        cancelBtnText={intl.get('cancelar')}
        cancelBtnBsStyle="danger"
        title={intl.get('FolderIndex.restore_title')}
        onConfirm={() => this.unfileDocument(oDocument)}
        onCancel={this.hideAlert}
      >
        <label className="split2" htmlFor="tree-view">
          {intl.get('FolderIndex.choose_your_folder')}
          <TreeViewLayer
            oFolderTree={oTreeViewFolder}
            fnSelectFolder={(evtSelectFolder) => this.handleChangeRestoreFolder(evtSelectFolder)}
            className="modal"
            cHeight="180px"
            bOutsideRender={true}
          />
        </label>
      </SweetAlert>
    );
  }

  validateDocumentRestore = (oDocument, oVersion) => {
    getDocumentDetails(oVersion.ver_nid).then(oData => {
      const oDocumentUpdated = oData.oDocument;
      if (oDocumentUpdated.folder.fldr_bisactive === 0 || oDocumentUpdated.folder.fldr_ddeleted != null) {
        this.setState({
          rcmpAlert: this.moveFiledDocumentAlert(oDocumentUpdated)
        });
      } else {
        this.unfileDocument(oDocumentUpdated);
      }
    });
  };

  handleDownloadPdf = (evtClick, nVersionId, cDocName) => {
    evtClick.preventDefault();
    const { setAlert } = this.props;

    this.setState({
      rcmpAlert: (
        <SweetAlert
          customClass="modal-edit modalMedium scrollBar"
          title=""
          onConfirm={this.hideAlert}
          showConfirm={false}
        >
          <div className="head-modal">
            {intl.get('DocumentDetails.print_config')}
            <span
              role="button"
              tabIndex="0"
              aria-labelledby="head-modal"
              onKeyPress={this.hideAlert}
              onClick={this.hideAlert}
              className="close"
              href=""
            />
          </div>
          <ScrollBar>
            <PrintConfig closeModal={this.hideAlert} nVersionId={nVersionId} cDocName={cDocName} />
          </ScrollBar>
        </SweetAlert>
      )
    });
  };

  renderMenuDocument = (oDocument, oVersion) => {
    let rcmpList = null;
    const cModule = 'documents';

    const { nVersionId, bCanEditInFolder, bDocActive } = this.state;
    const bNoMenu =
      bDocActive &&
      !(oDocument.bCanEdit || bCanEditInFolder) &&
      !(hasModule(cModule) && (oDocument.bCanEdit || can('print'))) &&
      !(hasModule(cModule) && (oDocument.bCanEdit || can('pending-reading')));

    if (!bDocActive) {
      rcmpList = (
        <>
          <li>
            <span
              role="button"
              tabIndex="0"
              onClick={() => this.validateDocumentRestore(oDocument, oVersion)}
              onKeyPress={() => this.validateDocumentRestore(oDocument, oVersion)}
              title={intl.get('DocumentDetails.restore')}
            >
              <i className="icon-document-remove" />
              {intl.get('DocumentDetails.restore')}
            </span>
          </li>
          <li>
            <NavLink title={intl.get('DocumentDetails.interacoes')} to={`/document/interaction/${oVersion.ver_nid}`}>
              <i className="icon-clock" />
              {intl.get('DocumentDetails.interacoes')}
            </NavLink>
          </li>
        </>
      );
    } else if (oDocument.bCanEdit || bCanEditInFolder) {
      rcmpList = (
        <>
          {hasModule(cModule) && (oDocument.bCanEdit || can('print')) && (
            <li>
              <span
                role="button"
                tabIndex="0"
                onKeyPress={evt => this.handleDownloadPdf(evt, oVersion.ver_nid, oDocument.doc_ctitle)}
                onClick={evt => this.handleDownloadPdf(evt, oVersion.ver_nid, oDocument.doc_ctitle)}
                title={intl.get('DocumentDetails.title_imprimir')}
              >
                <i className="icon-print" />
                {intl.get('DocumentDetails.imprimir')}
              </span>
            </li>
          )}
          {hasModule(cModule) && (oDocument.bCanEdit || can('shared-document')) && (
            <li>
              <span
                role="button"
                tabIndex="0"
                onKeyPress={evt => this.handleShareDocument(evt, oDocument.doc_nid)}
                onClick={evt => this.handleShareDocument(evt, oDocument.doc_nid)}
                title={intl.get('DocumentDetails.title_compartilhar')}
              >
                <i className="icon fa fa-share-alt"></i>
                {intl.get('DocumentDetails.title_compartilhar')}
              </span>
            </li>
          )}
          <li>
            <NavLink
              onClick={evt => this.openModalMoveDocument(evt, oDocument)}
              to={`/document/move/${oDocument.doc_nid}`}
              title={intl.get('DocumentDetails.title_mover')}
            >
              <i className="icon-move" />
              {intl.get('DocumentDetails.mover')}
            </NavLink>
          </li>
          <li>
            <NavLink to={`/document/copy/${nVersionId}`} title={intl.get('DocumentDetails.title_duplicar')}>
              <i className="icon-duplicate" />
              {intl.get('DocumentDetails.duplicar')}
            </NavLink>
          </li>

          <li>
            <NavLink
              onClick={evt => this.openModalEditDocument(evt)}
              to={`/document/edit/${oDocument.doc_nid}`}
              title={intl.get('DocumentDetails.title_editar_documento')}
            >
              <i className="icon-edit" />
              {intl.get('DocumentDetails.editar_documento')}
            </NavLink>
          </li>

          <li>
            <span
              role="button"
              tabIndex="0"
              onKeyPress={evt => this.showFile(evt, oDocument)}
              onClick={evt => this.showFile(evt, oDocument)}
              title={intl.get('DocumentDetails.title_arquivar')}
            >
              <i className="icon-document-remove" />
              {intl.get('DocumentDetails.arquivar')}
            </span>
          </li>

          <li>
            <NavLink
              title={intl.get('DocumentDetails.title_detalhe_de_leitura')}
              to={`/document/reading/${oVersion.ver_nid}`}
            >
              <i className="icon-eye-opened" />
              {intl.get('DocumentDetails.detalhe_de_leitura')}
            </NavLink>
          </li>

          <li>
            <NavLink title={intl.get('DocumentDetails.interacoes')} to={`/document/interaction/${oVersion.ver_nid}`}>
              <i className="icon-clock" />
              {intl.get('DocumentDetails.interacoes')}
            </NavLink>
          </li>
        </>
      );
    } else {
      rcmpList = (
        <>
          {hasModule(cModule) && (oDocument.bCanEdit || can('print')) && (
            <li>
              <span
                role="button"
                tabIndex="0"
                onKeyPress={evt => this.handleDownloadPdf(evt, oVersion.ver_nid, oDocument.doc_ctitle)}
                onClick={evt => this.handleDownloadPdf(evt, oVersion.ver_nid, oDocument.doc_ctitle)}
                title={intl.get('DocumentDetails.title_imprimir')}
              >
                <i className="icon-print" />
                {intl.get('DocumentDetails.imprimir')}
              </span>
            </li>
          )}
          {hasModule(cModule) && (oDocument.bCanEdit || can('shared-document')) && (
            <li>
              <span
                role="button"
                tabIndex="0"
                onKeyPress={evt => this.handleShareDocument(evt, oDocument.doc_nid)}
                onClick={evt => this.handleShareDocument(evt, oDocument.doc_nid)}
                title={intl.get('DocumentDetails.title_compartilhar')}
              >
                <i className="icon fa fa-share-alt"></i>
                {intl.get('DocumentDetails.title_compartilhar')}
              </span>
            </li>
          )}
          {hasModule(cModule) && (oDocument.bCanEdit || can('pending-reading')) && (
            <li>
              <NavLink
                title={intl.get('DocumentDetails.title_detalhe_de_leitura')}
                to={`/document/reading/${oVersion.ver_nid}`}
              >
                <i className="icon-eye-opened" />
                {intl.get('DocumentDetails.detalhe_de_leitura')}
              </NavLink>
            </li>
          )}
        </>
      );
    }
    if (bNoMenu) {
      return <></>;
    }

    const { bOpenState } = this.state;
    return (
      <OutsideHandlerClick handleClickOutside={this.handleClickOutside}>
        <ul
          ref={cMenu => {
            this.menu = cMenu;
          }}
          className="tools"
          style={{ display: bOpenState ? 'block' : 'none' }}
        >
          {rcmpList}
        </ul>
      </OutsideHandlerClick>
    );
  };

  showFile = (evt, oDocument) => {
    this.setState({
      rcmpAlert: (
        <SweetAlert
          showCancel
          confirmBtnText={intl.get('confirmar')}
          cancelBtnText={intl.get('cancel')}
          cancelBtnBsStyle="danger"
          title={intl.get('DocumentDetails.arquivar')}
          onConfirm={() => this.handleFile(oDocument)}
          onCancel={this.hideAlert}
        >
          {intl.get('DocumentDetails.arquivar_pergunta')} &quot;{oDocument.doc_ctitle}&quot;?
        </SweetAlert>
      )
    });
  };

  openModalEditDocument = evt => {
    const { oDocument } = this.state;
    const { history } = this.props;
    evt.preventDefault();
    this.setState({
      rcmpAlert: (
        <SweetAlert
          customClass="modal-edit modal-edit-v2 lg scrollBar"
          title=""
          onConfirm={this.hideAlert}
          showConfirm={false}
        >
          <div className="head-modal">
            {intl.get('DocumentDetails.editar_documento')}
            <span
              role="button"
              tabIndex="0"
              aria-labelledby="head-modal"
              onKeyDown={this.handleKeyDown}
              onClick={this.hideAlert}
              className="close"
              href=""
            />
          </div>
          <ScrollBar>
            <DocumentEdit
              onSuccess={cMsg => {
                history.push({
                  state: { aAlertMessages: { cType: 'success', cMsg } }
                });
              }}
              closeModal={this.hideAlert}
              oDocument={oDocument}
            />
          </ScrollBar>
        </SweetAlert>
      )
    });
  };

  openModalNewVersion = evt => {
    const { nVersionId, oVersion } = this.state;
    const { setAlert, history, match } = this.props;

    evt.preventDefault();
    Axios.get(`version/validate/new-version/${oVersion.doc_nid}`)
      .then(oResponse => {
        this.setState({
          rcmpAlert: (
            <SweetAlert
              customClass="modal-edit md disabled-overflow scrollBar"
              title=""
              onConfirm={this.hideAlert}
              showConfirm={false}
            >
              <div className="head-modal">
                {intl.get('NewVersion.title')}
                <span
                  role="button"
                  tabIndex="0"
                  aria-labelledby="head-modal"
                  onKeyDown={this.handleKeyDown}
                  onClick={this.hideAlert}
                  className="close"
                  href=""
                />
              </div>
              <ScrollBar>
                <NewVersion
                  onSuccess={cMsg => {
                    this.successMessage(cMsg);
                  }}
                  closeModal={this.hideAlert}
                  nVersionId={nVersionId}
                  nNumberVersion={oVersion.ver_cnumber}
                  history={history}
                  match={match}
                />
              </ScrollBar>
            </SweetAlert>
          )
        });
      })
      .catch(oError => {
        setAlert('error', oError.aMsgErrors);
      });
  };

  handleFile = oDocument => {
    const { history } = this.props;

    fileDocument(oDocument.doc_nid)
      .then(() => {
        this.setState({
          rcmpAlert: (
            <SweetAlert
              success
              title=""
              onConfirm={() => {
                this.hideAlert();
                history.push(`/folder/${oDocument.fldr_nid}`);
              }}
            >
              {intl.get('DocumentDetails.documento_arquivado')}
            </SweetAlert>
          )
        });
      })
      .catch(oError => {
        this.setState({
          rcmpAlert: (
            <SweetAlert error title="" onConfirm={this.hideAlert}>
              {oError.aMsgErrors}
            </SweetAlert>
          )
        });
      });
  };

  hideAlert = () => {
    this.setState({
      rcmpAlert: null
    });
  };

  successMessage = cMsg => {
    const { setAlert } = this.props;
    this.hideAlert();
    setAlert('success', cMsg);
  };

  openRequisite = (evt, oRequisite) => {
    this.setState({
      rcmpAlert: (
        <SweetAlert
          customClass="modal-edit lg disabled-overflow scrollBar"
          title=""
          onConfirm={this.hideAlert}
          showConfirm={false}
        >
          <div className="head-modal">
            {oRequisite.req_cdescription}
            <span
              role="button"
              tabIndex="0"
              aria-labelledby="head-modal"
              onKeyPress={this.hideAlert}
              onClick={this.hideAlert}
              className="close"
              href=""
            />
          </div>
          <RequisiteShow
            nRequisiteId={oRequisite.req_nid}
            nNormId={oRequisite.norm_nid}
            oRequisite={oRequisite}
            onSuccess={cMsg => {
              this.successMessage(cMsg);
            }}
            closeModal={this.hideAlert}
          />
        </SweetAlert>
      )
    });
  };

  generateCardHeader = () => {
    const { history } = this.props;
    const { oDocument, oVersion } = this.state;

    const cHtml = (
      <>
        <span
          role="button"
          tabIndex="0"
          onKeyDown={this.handleKeyDown}
          onClick={evt => this.openMenu(evt)}
          title={intl.get('DocumentDetails.title_opcoes_doc')}
          className="btn-menu"
        >
          <span />
        </span>
        {this.renderMenuDocument(oDocument, oVersion)}
      </>
    );

    const rcmpCardHeader = <CardHeader cHtml={cHtml} history={history} />;

    return rcmpCardHeader;
  };

  render() {
    const {
      rcmpAlert,
      oDocument,
      oVersion,
      cPreProcessedText,
      oDocumentWriter,
      oDocumentReviewer,
      oDocumentResponsible,
      aAttachments,
      bConfirmedRead,
      bCanEditInFolder,
      bPendingRead,
      aBreadcrumbFolders,
      bGenZip,
      bLoading,
      aRequisites,
      bDocActive,
      bShowSpinner,
      shareData,
      oTreeViewFolder
    } = this.state;
    const { getAlert } = this.props;
    const nUserId = oLocalStorage.get('nUserId');
    const bIsResponsible = oDocumentResponsible.user_nid == nUserId;
    const bDocTitleLoaded = oDocument.doc_ctitle !== undefined && oDocument.doc_ctitle.length > 35;

    const rcmpBreadcrumb = (
      <div className="breadcrumbs-wrapper">
        <div className="inner-bread">
          <Breadcrumb bLastLink aFolders={aBreadcrumbFolders} key={oDocument.doc_nid} />
        </div>

        <span title={bDocTitleLoaded ? oDocument.doc_ctitle : ''}>
          {bDocTitleLoaded ? oDocument.doc_ctitle.trunc(35) : oDocument.doc_ctitle}
        </span>
      </div>
    );

    const rcmpCardHeader = this.generateCardHeader();

    const cPageTitle = oDocument.doc_ctitle;

    return (
      <Page
        loading={bLoading ? 1 : 0}
        rcmpBreadcrumb={rcmpBreadcrumb}
        cTitle={cPageTitle}
        cImage={documentImg}
        rcmpCardHeader={rcmpCardHeader}
        className="v2-document-details"
        cCurrentSideMenuOption={intl.get('Nav.document')}
      >
        {rcmpAlert}
        {bShowSpinner && (
          <div style={{
            position: 'fixed', // Fixa em relação ao viewport
            top: 0,            // Começa no topo do viewport
            left: 0,           // Alinhado à esquerda do viewport
            right: 0,          // Estende até a direita do viewport
            bottom: 0,         // Estende até o fundo do viewport
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Cor de fundo semi-transparente
            display: 'flex',
            justifyContent: 'center', // Centraliza horizontalmente o conteúdo
            alignItems: 'center',     // Centraliza verticalmente o conteúdo
            zIndex: 1000              // Garante que a sobreposição esteja acima de outros elementos
          }}>
            {/* <TailSpin
              type="Puff"
              color="#00BFFF"
              height={100}
              width={100}
            /> */}
          </div>
        )}
        {getAlert()}
        <div className="clearfix" />
        <article id="document-info">
          <header id="document-header">
            <div className="document-container-info">
              <div className="document-justification">
                <h5>{intl.get('justificativa')}</h5>
                <p title={oVersion.ver_cjustification}>{oVersion.ver_cjustification}</p>
              </div>

              <div className="document-roles">
                <div className="role-avatar">
                  <strong className="header">{intl.get('responsible')}</strong>
                  <span className="avatar">
                    <img
                      src={
                        oVersion.version_responsible?.user_cavatar
                          ? oVersion.version_responsible?.user_cavatar
                          : imgfNoPic
                      }
                      alt={oVersion.version_responsible?.user_cname}
                    />
                  </span>
                  <span className="info">
                    <span className="name" title={oVersion.version_responsible?.user_cname}>
                      {oVersion.version_responsible?.user_cname}
                    </span>
                  </span>
                </div>

                <div className="role-avatar">
                  <strong className="header">{intl.get('writer')}</strong>
                  <span className="avatar">
                    <img
                      src={oVersion.version_writer?.user_cavatar ? oVersion.version_writer?.user_cavatar : imgfNoPic}
                      alt={oVersion.version_writer?.user_cname}
                    />
                  </span>
                  <span className="info">
                    <span className="name" title={oVersion.version_writer?.user_cname}>
                      {oVersion.version_writer?.user_cname}
                    </span>
                  </span>
                </div>

                <div className="role-avatar">
                  <strong className="header">{intl.get('revisor')}</strong>
                  <span className="avatar">
                    <img
                      src={
                        oVersion.version_reviewer?.user_cavatar ? oVersion.version_reviewer?.user_cavatar : imgfNoPic
                      }
                      alt={oVersion.version_reviewer?.user_cname}
                    />
                  </span>
                  <span className="info">
                    <span className="name" title={oVersion.version_reviewer?.user_cname}>
                      {oVersion.version_reviewer?.user_cname}
                    </span>
                  </span>
                </div>

                <div className="role-avatar">
                  <strong className="header">{intl.get('ConfigHeaderFooter.version')}</strong>
                  <span
                    title={oVersion.ver_cnumber}
                    className={`titleVersion ${oVersion.document?.doc_eversiontype === 'M' ? 'manual' : ''}`}
                  >
                    {oVersion.ver_cnumber}
                  </span>
                  {bDocActive && (
                    <NavLink
                      to={`/document/history/${oVersion.document?.doc_nid}`}
                      title={intl.get('DocumentDetails.title_ver_historico')}
                      className="versionsLink"
                    >
                      {intl.get('DocumentDetails.ver_historico')}
                    </NavLink>
                  )}
                </div>
              </div>
            </div>

            <div className="document-container-dates">
              <div className="dates">
                {intl.get('FolderIndex.published_at')} &nbsp;
                <strong>
                  {formatDateToUserTZ(oVersion.ver_dpublished, 'DD/MM/YYYY')}
                </strong>
                {oVersion.ver_dwritten && (
                  <>
                    , {intl.get('FolderIndex.editado_em')} &nbsp;
                    <strong>
                      {formatDateToUserTZ(oVersion.ver_dwritten, 'DD/MM/YYYY')}
                    </strong>
                  </>

                )}
                {_.toArray(oVersion.version_readings).length > 0 && (
                  <>
                    , &nbsp; {intl.get('DocumentDetails.title_lido')} &nbsp;
                    <strong>
                      {formatDateToUserTZ(oVersion.version_readings[0].ver_dread, 'DD/MM/YYYY')}
                    </strong>
                  </>
                )}
              </div>
              {aRequisites && (
                <div className="requisites">
                  {Object.keys(aRequisites).map(cItem => (
                    <span
                      role="button"
                      tabIndex="0"
                      key={aRequisites[cItem].req_nid}
                      onKeyDown={this.handleKeyDown}
                      onClick={evt => {
                        this.openRequisite(evt, aRequisites[cItem]);
                      }}
                      title={aRequisites[cItem].req_cdescription}
                    >
                      {aRequisites[cItem].req_cdescription}
                    </span>
                  ))}
                </div>
              )}
            </div>
          </header>
          {!bDocActive ? (
            <Watermark text={intl.get('FolderIndex.filed_documents')} opacity="0.8">
              <div className="fr-wrapper">
                <article
                  className="fr-element fr-view"
                  dangerouslySetInnerHTML={{ __html: cPreProcessedText ?? '' }}
                />
              </div>
            </Watermark>
          ) : (
            <div className="fr-wrapper">
              <article
                className="fr-element fr-view"
                dangerouslySetInnerHTML={{ __html: cPreProcessedText ?? '' }}
              />
            </div>
          )}
        </article>

        <aside id="document-attachments">
        {(can('print') || canAtLeast('document-reading') || canAtLeast('documents') || (bCanEditInFolder) || (oTreeViewFolder.canCreate)) && aAttachments.length > 0 && (
            <div className="attachments-wrapper">
              <span>{intl.get('anexos')}:</span>
              <ul className="attachments">
                {aAttachments.map((oAttachment, i) => (
                  <li key={oAttachment.atch_nid}>
                    <a
                      className="download-file-attachment"
                      onClick={evt => this.handleDownloadAttachment(evt, oAttachment)}
                    >
                      <img
                        className="align clip-icon"
                        src={require(`../../assets/img/clip.svg`)}
                        alt="attachment icon"
                      />
                      <span>{oAttachment.atch_cname}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </aside>

        {this.state.oVersion.ver_cfile_path && (
          <aside id="download-document">
            <a className="custom-hyperlink bold" onClick={evt => this.fnHandleDownloadFile(evt)}>
              <img className="align" src={require(`../../assets/img/download.svg`)} alt="download icon" />
              <span>Download do documento completo</span>
            </a>
          </aside>
        )}

        <div className="button-stack">
          <Button
            className="btn btn-cancel"
            onClick={evt => {
              this.props.history.goBack();
            }}
          >
            {intl.get('cancelar')}
          </Button>
          {bDocActive && (
            <>
              {bIsResponsible ? (
                <button type="submit" className="btn alternate" onClick={evt => this.openModalNewVersion(evt)}>
                  {intl.get('DocumentDetails.nova_versao')}
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn alternate"
                  onClick={evt => this.openModalRequest(evt, oVersion.ver_nid)}
                >
                  {intl.get('DocumentDetails.suggest_changes')}
                </button>
              )}
            </>
          )}
          {bDocActive && bConfirmedRead === false && bPendingRead === true && (
            <button onClick={evt => this.handleConfirmReading(evt)} type="submit" className="btn">
              {intl.get('DocumentDetails.confirmar_leitura')}
            </button>
          )}
        </div>

        {bGenZip && (
          <div style={{ display: 'block' }} className="fp-loader">
            <div className="inner">
              <i className="ico" style={{ display: 'block' }} />
              <p>{intl.getHTML('DocumentDetails.criando_zip')}</p>
            </div>
          </div>
        )}
      </Page>
    );
  }
}

export default DocumentDetails;
